import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import 'rxjs/Rx';

import * as Papa from 'papaparse';
import { SchoolActiveDTO } from '@app/CoreModule/Models/School';
import { SchoolsService, UsersService, AuthService } from '@app/CoreModule/Services';

@Component({
  selector: 'app-import-schools',
  templateUrl: './import-schools.component.html',
  styleUrls: ['./import-schools.component.css']
})
export class ImportSchoolsComponent implements OnInit {
  fileInput: File;
  status: boolean = null;
  statusTextfield: string = '';
  schoolsToImport = [];

  results: any;

  constructor(
    private schoolService: SchoolsService,
    private usersService: UsersService,
    private authService: AuthService,
    public thisDialogRef: MatDialogRef<ImportSchoolsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) { }

  ngOnInit() { }

  getHelpFile() {
    this.getFile(0, 'Import Schools guide.pdf');
    return false;
  }

  getCSVFile() {
    this.getFile(3, 'Schools examples.csv');
    return false;
  }

  getFile(fileId: number, fileName: string) {
    this.usersService.getHelpFile(fileId).then(
      (data) => {
        if (data) {
          const downloadedFile = new Blob([data['body']], {
            type: data['body'].type
          });
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = fileName;
          a.href = URL.createObjectURL(downloadedFile);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
        }
      },
      (error) =>
        this.authService.alertError(
          'An error occurred while requesting the file from the server. Please try again.'
        )
    );
  }

  handleFileInput(files: FileList) {
    this.status = null;
    this.statusTextfield = '';

    this.fileInput = files.item(0);
    if (this.fileInput) {
      Papa.parse(this.fileInput, {
        encoding: 'cp1253', // iso-8859-7
        skipEmptyLines: true,
        complete: (results) => {
          this.checkCSVFile(results.data);
        }
      });
    }
  }

  searchFileName() {
    if (this.fileInput) return this.fileInput.name;
    else return 'No file selected.';
  }

  setErrors(status, statusTextfield) {
    this.status = status;
    this.statusTextfield = statusTextfield;
  }

  checkCSVFile(results) {
    if (!results || results.length == 0) {
      this.setErrors(false, 'Error: No data found in the CSV file.');
      return;
    }

    if (
      results[0].includes('sep=') ||
      results[0].includes('sep=;') ||
      results[0].includes('sep=,')
    ) {
      results.shift();
    }

    const columnMappings = {
      'school_name*': 'Name',
      'address_1*': 'Address',
      'city*': 'City',
      'country*': 'Country',
      'state*': 'State',
      'zip*': 'Zip',
      'admin_first_name*': 'Admin.Name',
      'admin_last_name*': 'Admin.Surname',
      'admin_email*': 'Admin.Email',
      'admin_title': 'Admin.JobTitle'
    };

    const requiredTitles = [
      'school_name*',
      'address_1*',
      'city*',
      'state*',
      'zip*',
      'country*',
      'admin_first_name*',
      'admin_last_name*',
      'admin_email*'
    ];

    const missingRequiredTitles = [];
    requiredTitles.forEach((title) => {
      if (results[0].indexOf(title) == -1) {
        missingRequiredTitles.push(title);
      }
    });

    if (missingRequiredTitles.length > 0) {
      const missingTitlesString = missingRequiredTitles.join(', ');
      this.setErrors(false, `Error: Missing required titles: ${missingTitlesString}.`);
      return;
    }

    const fileSplit = this.fileInput.name.split('.');
    const fileType = fileSplit[fileSplit.length - 1];
    if (fileType != 'csv') {
      this.setErrors(false, `Error: Wrong file format, .${fileType} instead of .csv.`);
      return;
    }

    const requiredTitlesIndex = [];
    requiredTitles.forEach((title) => {
      requiredTitlesIndex.push(results[0].indexOf(title));
    });

    let counter = 0;
    const removedIndexes = [];
    const resLength = results.length;
    for (let i = 1; i < resLength; i++) {
      const row = results[i - counter];
      let emptyRow = true;
      for (let j = 1; j < results.length; j++) {
        const columnValue = row[j];
        if (columnValue != '' && columnValue != undefined) {
          emptyRow = false;
          break;
        }
      }
      if (emptyRow) {
        this.statusTextfield += `Update: Row ${i + 1} - Deleted empty row.\n`;
        removedIndexes.push(i - counter);
        results.splice(i - counter, 1);
        counter++;
      }
    }

    for (let i = 1; i < results.length; i++) {
      requiredTitlesIndex.forEach((ind) => {
        const column = results[i][ind];
        if (column == '') {
          this.statusTextfield += `Error: Row ${i + 1 + removedIndexes.filter((ind) => {
            return ind < i + 1;
          }).length} - Empty field in title ${results[0][ind]}.\n`;
        }
      });
      results[i].forEach(function (column, j) {
        if (column != '' && column.trim().length == 0) {
          this.statusTextfield += `Warning: Row ${i + 1 + removedIndexes.filter((ind) => {
            return ind < i + 1;
          }).length} - This field consists only of empty spaces in title. ${results[0][j]}.\n`;
        }
      });
    }

    if (this.statusTextfield != '' && this.statusTextfield.search('Error:') != -1) {
      this.setErrors(false, this.statusTextfield);
    } else {
      this.results = results;
      this.schoolsToImport = this.prepareArray();

      this.schoolService.getSchoolsActiveState().subscribe(
        (schools: SchoolActiveDTO[]) => {
          let activeCounter = 0;
          let notActiveCounter = 0;
          let newCounter = 0;
          this.schoolsToImport.forEach((school) => {
            const sch = schools.find((s) => s.Name == school.Name);
            if (sch) {
              school.Id = sch.Id;
              if (sch['Active']) activeCounter++;
              else notActiveCounter++;
            } else {
              newCounter++;
            }
            school['PhoneNumber'] = ''; // required for add school
          });

          this.setErrors(
            true,
            `Correct file format.\n\nNew schools:\t\t\t\t${newCounter}\nActivated schools:\t\t\t${activeCounter}\nDeactivated schools:\t\t${notActiveCounter}\n\nTo the already existent schools, activated or not, values will be updated according to data in the .CSV file. All schools from the file will be set as 'Activated'.\n\nPress the button 'Import schools' to continue.`
          );
        },
        (error) =>
          this.authService.alertError(
            'An error occurred while checking school data. Please try again.'
          )
      );
    }
  }

  prepareArray() {
    for (let i = 0; i < this.results.length; i++) {
      for (let j = 0; j < this.results[0].length; j++) {
        this.results[i][j] = this.results[i][j].trim();
      }
    }

    const columnMappings = {
      'school_name*': 'Name',
      'address_1*': 'Address',
      'city*': 'City',
      'country*': 'Country',
      'state*': 'State',
      'zip*': 'Zip',
      'admin_first_name*': 'Admin.Name',
      'admin_last_name*': 'Admin.Surname',
      'admin_email*': 'Admin.Email',
      'admin_title': 'Admin.JobTitle'
    };

    const allSchools = [];
    let schoolRow;
    let school;
    for (let i = 1; i < this.results.length; i++) {
      schoolRow = this.results[i];
      school = {};
      for (let j = 0; j < schoolRow.length; j++) {
        const csvColumnName = this.results[0][j];
        const modelFieldName = columnMappings[csvColumnName];
        if (modelFieldName) {
          if (modelFieldName.indexOf('.') !== -1) {
            const attributes = modelFieldName.split('.');
            if (!school[attributes[0]]) {
              school[attributes[0]] = {};
            }
            school[attributes[0]][attributes[1]] = schoolRow[j];
          } else {
            school[modelFieldName] = schoolRow[j];
          }
        }
      }
      allSchools.push(school);
    }

    return allSchools;
  }

  onCloseConfirm() {
    this.thisDialogRef.close(this.schoolsToImport);
    setTimeout(() => {
      window.location.reload(); // TODO pass imported data to main page
    }, 2500);
  }

  onCloseCancel() {
    this.thisDialogRef.close(false);
  }
}