import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import 'rxjs/Rx';

import * as Papa from "papaparse";
import { StaffActiveDTO } from '@app/CoreModule/Models/Staff';
import { AuthService, UsersService, StaffService } from '@app/CoreModule/Services';

@Component({
    selector: 'app-import-staff',
    templateUrl: './import-staff.component.html',
    styleUrls: ['./import-staff.component.css']
})
export class ImportStaffComponent implements OnInit {
    fileInput: File;
    status: boolean = null;
    statusTextfield = "";
    staffToImport = [];

    results: any;

    constructor(private authService: AuthService, private usersService: UsersService, private staffService: StaffService, public thisDialogRef: MatDialogRef<ImportStaffComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

    ngOnInit() {
    }

    getHelpFile() {
        this.getFile(1, "Import Staff guide.pdf");
        return false;
    }

    getCSVFile() {
        this.getFile(4, "Staff examples.csv");
        return false;
    }

    getFile(fileId: number, fileName: string) {
        this.usersService.getHelpFile(fileId).then(
            data => {
                if (data) {
                    const downloadedFile = new Blob([data['body']], { type: data['body'].type });
                    const a = document.createElement('a');
                    a.setAttribute('style', 'display:none;');
                    document.body.appendChild(a);
                    a.download = fileName;
                    a.href = URL.createObjectURL(downloadedFile);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                }
            },
            error => this.authService.alertError("An error occurred while requesting the file from the server. Please try again.")
        );
    }

    handleFileInput(files: FileList) {
        this.status = null;
        this.statusTextfield = "";

        this.fileInput = files.item(0);
        if (this.fileInput) {
            Papa.parse(this.fileInput, {
                encoding: 'cp1253', //iso-8859-7
                skipEmptyLines: true,
                complete: (results) => {
                    this.checkCSVFile(results.data);
                }
            });
        }
    }

    searchFileName() {
        if (this.fileInput) return this.fileInput.name;
        else return "No file.";
    }
    setErrors(status, statusTextfield) {
        this.status = status;
        this.statusTextfield = statusTextfield;
    }

    checkCSVFile(results) {
        if (!results || results.length == 0) return;

        if (results[0].includes("sep=") || results[0].includes("sep=;") || results[0].includes("sep=,")) {
            results.shift();
        }

        var titles = [
            "first_name*", "last_name*", "email*", "role*", "title", "school_name_1", "school_name_2", "school_name_3", "school_name_4"
        ]

        var requiredTitles = [
            "first_name*", "last_name*", "email*", "role*"
        ]

        var status = false;
        var statusTextfield: String = "";

        var fileSplit = this.fileInput.name.split('.');
        var fileType = fileSplit[fileSplit.length - 1];
        if (fileType != "csv") statusTextfield += "Error: Wrong file format, ." + fileType + " instead of .csv.\n"

        if (statusTextfield != "") return this.setErrors(status, statusTextfield);

        titles.forEach(title => {
            if (results[0].indexOf(title) == -1) statusTextfield += "Error: Missing title " + title + ".\n";
        });
        if (statusTextfield != "") return this.setErrors(status, statusTextfield);

        var requiredTitlesIndex = [];
        requiredTitles.forEach(title => {
            requiredTitlesIndex.push(results[0].indexOf(title))
        });

        var counter = 0;
        var removedIndexes = [];
        var resLength = results.length;
        for (var i = 1; i < resLength; i++) {
            var row = results[i - counter];
            var emptyRow = true;
            for (var j = 1; j < results.length; j++) {
                var columnValue = row[j];
                if (columnValue != "" && columnValue != undefined) {
                    emptyRow = false;
                    break;
                }
            };
            if (emptyRow) {
                statusTextfield += "Update: Row " + (i + 1) + " - Deleted empty row.\n";
                removedIndexes.push(i - counter);
                results.splice(i - counter, 1);
                counter++;
            }
        };

        for (var i = 1; i < results.length; i++) {
            requiredTitlesIndex.forEach(ind => {
                var column = results[i][ind];
                if (column == "") statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Empty field in title " + results[0][ind] + ".\n";
            });
            results[i].forEach(function (column, j) {
                if (column != "" && column.trim().length == 0) statusTextfield += "Warning: Row" + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - This field consists only of empty spaces in title " + results[0][j] + ".\n";
            });
        }
        if (statusTextfield != "" && statusTextfield.search("Error:") != -1) return this.setErrors(status, statusTextfield);
        else {
            this.results = results;
            this.staffToImport = this.prepareArray();

            this.staffService.getStaffActiveState().subscribe(
                (staffStatus: StaffActiveDTO[]) => {
                    var activeCounter = 0;
                    var notActiveCounter = 0;
                    var newCounter = 0;
                    this.staffToImport.forEach(staff => {
                        var st = staffStatus.filter(s => s.Email == staff.Email)[0];
                        if (st) {
                            staff.Id = st.Id;
                            if (st['Active']) activeCounter++;
                            else notActiveCounter++;
                        }
                        else {
                            newCounter++;
                        }
                    });

                    status = true;
                    if (statusTextfield != "") statusTextfield += "\n";
                    statusTextfield += "Correct file format.\n\n"
                        + "New staff:\t\t\t\t" + newCounter.toString() + "\n"
                        + "Activated staff:\t\t\t" + activeCounter.toString() + "\n"
                        + "Deactivated staff:\t\t\t" + notActiveCounter.toString() + "\n\n"
                        + "To the already existent staff, activated or not, values will be updated according to data in the .CSV file. All staff from the file will be set as 'Deactivated'.\n\n"
                        + "Press the button 'Import Staff' to continue.";

                    return this.setErrors(status, statusTextfield);
                },
                errors => this.authService.alertError("An error occurred while checking staff data. Please try again.")
            );
        }
    }

    prepareArray() {
        for (let i = 0; i < this.results.length; i++) {
            for (let j = 0; j < this.results[0].length; j++) {
                this.results[i][j] = this.results[i][j].trim();
            }
        }

        var staffAttributes = [];

        this.results[0].forEach(title => {
            if (title == "first_name*") staffAttributes.push('Name');
            else if (title == "last_name*") staffAttributes.push('Surname');
            else if (title == "email*") staffAttributes.push('Email');
            else if (title == "title") staffAttributes.push('JobTitle');
            else if (title == "role*") staffAttributes.push('RoleName');
            else if (title == "school_name_1") staffAttributes.push('School1');
            else if (title == "school_name_2") staffAttributes.push('School2');
            else if (title == "school_name_3") staffAttributes.push('School3');
            else if (title == "school_name_4") staffAttributes.push('School4');
        });

        var allStaff = [];
        var staffRow;
        var staff: Object;
        for (var i = 1; i < this.results.length; i++) {
            staffRow = this.results[i];
            staff = {};
            for (var j = 0; j < staffAttributes.length; j++) {
                if (staffAttributes[j] === 'RoleName') {
                    // Convert role to lowercase values
                    var lowercaseRole = staffRow[j].toLowerCase();
                    if (lowercaseRole === 'rater_only') {
                        staff[staffAttributes[j]] = 'Rater Only';
                    } else if (lowercaseRole === 'school_staff') {
                        staff[staffAttributes[j]] = 'School Staff';
                    } else if (lowercaseRole === 'school_admin') {
                        staff[staffAttributes[j]] = 'School Admin';
                    } else if (lowercaseRole === 'district_staff') {
                        staff[staffAttributes[j]] = 'District Staff';
                    } else if (lowercaseRole === 'district_admin') {
                        staff[staffAttributes[j]] = 'District Admin';
                    } else {
                        staff[staffAttributes[j]] = staffRow[j];
                    }
                } else {
                    staff[staffAttributes[j]] = staffRow[j];
                }
            }
            allStaff.push(staff);
        }

        return allStaff;
    }


    onCloseConfirm() {
        this.thisDialogRef.close(this.staffToImport);
        setTimeout(() => {
            window.location.reload(); // TODO pass imported data to main page
        }, 2500);
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }
}