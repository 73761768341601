
export class UAPeriod {
    Id: number;
    Name: string;
    StartDate: string;
    EndDate: string;
    GuardianAssessment: boolean;
    StudentSelfAssessment: boolean;

    constructor() {
        this.Id = 0;
        this.Name = "";
        this.StartDate = null;
        this.EndDate = null;
        this.GuardianAssessment = false;
        this.StudentSelfAssessment = false;
    }
}

export interface UAPeriodDTO {
    Id: number;
    Name: string;
    StartDate: string;
    EndDate: string;
    GuardianAssessment: boolean;
    StudentSelfAssessment: boolean;
    AreResultsCalculated: boolean;
}

export interface GetStudentLinkDTO {
    StudentId: string;
    School: string;
    Grade: number;
    UATeacher: string;
    FirstName: string;
    LastName: string;
    Link: string;
}

export interface GetParentLinkDTO {
    GuardianId: number;
    FirstName: string;
    LastName: string;
    StudentFirstName: string;
    StudentLastName: string;
    Link: string;
}

export interface EmailRequestDTO {
    DateApplied: string;
    UserType: string;
    EmailType: string;
    Status: string;
    EmailsSent: number;
    Schools: string[];
    Grades: string[];
}

export interface UaPeriodIdNameDTO {
    Id: number;
    Name: string;
}
