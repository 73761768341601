import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationBarComponent } from './Components/navigation-bar/navigation-bar.component';
import { SharedModule } from '@app/SharedModule/shared/shared.module';
import { RouterModule } from '@angular/router';
import { SchoolsService, StaffService, StudentsService, UaPeriodService, ReportsService, UsersService, AuthService, AssessmentService, AccountsService } from './Services';
import { ImportSchoolsComponent } from '@app/FeatureModules/admin/schools/Components/import-schools/import-schools.component';
import { ImportStaffComponent } from '@app/FeatureModules/admin/staff/Components/import-staff/import-staff.component';
import { ImportStudentsComponent } from '@app/FeatureModules/admin/students/Components/import-students/import-students.component';
import { AddEditAdminUaComponent } from '@app/SharedModule/shared/Components/ua-periods/add-edit-admin-ua/add-edit-admin-ua.component';


@NgModule({
  declarations: [
    NavigationBarComponent,
    ImportSchoolsComponent,
    ImportStaffComponent,
    ImportStudentsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [
    NavigationBarComponent
  ],
  providers: [
    SchoolsService,
    StaffService,
    StudentsService,
    UaPeriodService,
    ReportsService,
    UsersService,
    AuthService,
    AssessmentService,
    AccountsService,
  ],
  entryComponents: [
    ImportSchoolsComponent,
    ImportStaffComponent,
    ImportStudentsComponent,
    AddEditAdminUaComponent,
  ]
})
export class CoreModule { }
