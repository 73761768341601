import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import 'rxjs/Rx';

import * as Papa from "papaparse";
import { StudentActiveDTO } from '@app/CoreModule/Models/Student';
import { AuthService, UsersService, StudentsService } from '@app/CoreModule/Services';

@Component({
    selector: 'app-import-students',
    templateUrl: './import-students.component.html',
    styleUrls: ['./import-students.component.css']
})
export class ImportStudentsComponent implements OnInit {
    fileInput: File;
    status: boolean = null;
    statusTextfield = "";
    studentsToImport = [];

    results: any;

    constructor(private authService: AuthService, private usersService: UsersService, private studentsService: StudentsService, public thisDialogRef: MatDialogRef<ImportStudentsComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

    ngOnInit() {
    }

    getHelpFile() {
        this.getFile(2, "Import Students guide.pdf");
        return false;
    }

    getCSVFile() {
        this.getFile(5, "Students examples.csv");
        return false;
    }

    getFile(fileId: number, fileName: string) {
        this.usersService.getHelpFile(fileId).then(
            data => {
                if (data) {
                    const downloadedFile = new Blob([data['body']], { type: data['body'].type });
                    const a = document.createElement('a');
                    a.setAttribute('style', 'display:none;');
                    document.body.appendChild(a);
                    a.download = fileName;
                    a.href = URL.createObjectURL(downloadedFile);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                }
            },
            error => this.authService.alertError("An error occurred while requesting the file from the server. Please try again.")
        );
    }

    handleFileInput(files: FileList) {
        this.status = null;
        this.statusTextfield = "";

        this.fileInput = files.item(0);

        if (this.fileInput) {
            Papa.parse(this.fileInput, {
                encoding: 'cp1253', //iso-8859-7
                skipEmptyLines: true,
                complete: (results) => {
                    this.checkCSVFile(results.data);
                }
            });
        }
    }

    searchFileName() {
        if (this.fileInput) return this.fileInput.name;
        else return "No file selected.";
    }

    setErrors(status, statusTextfield) {
        this.status = status;
        this.statusTextfield = statusTextfield;
    }

    checkCSVFile(results) {

        var status = false;
        var statusTextfield: String = "";

        if (!results || results.length == 0) return;

        if (results[0].includes("sep=") || results[0].includes("sep=;") || results[0].includes("sep=,")) {
            results.shift();
        }

        var titles = [
            "first_name*", "last_name*", "student_id*", "student_email", "school*", "grade*", "ua_teacher_email", "birth_date*", "gender", "race", /*"ethnicity",*/ "hispanic",
            "mtss*", "cohort", "idea", "disability", "meal_status", "esl", "title_1", "section_504", "gifted_talented", "mobility", "correctional",
            "prior_retentions", "absences", "referrals", "intervention_type",
            "guardian_1_first_name", "guardian_1_last_name", "guardian_1_email", "guardian_1_relationship", /*"guardian_1_custody",*/
            "guardian_2_first_name", "guardian_2_last_name", "guardian_2_email", "guardian_2_relationship", /*"guardian_2_custody"*/];

        var requiredTitles = [
            "first_name*", "last_name*", "student_id*", "school*", "grade*", "birth_date*", "mtss*"];

        var emptyRequiredFields = [];

        for (var i = 1; i < results.length; i++) {
            requiredTitles.forEach(title => {
                var titleIndex = results[0].indexOf(title);
                var columnValue = results[i][titleIndex];
                if (!columnValue || columnValue.trim() === "") {
                    emptyRequiredFields.push({ row: i + 1, title: title });
                }
            });
        }

        if (emptyRequiredFields.length > 0) {
            emptyRequiredFields.forEach(field => {
                statusTextfield += "Error: Row " + field.row + " - Empty value in required field: " + field.title + ".\n";
            });
            return this.setErrors(status, statusTextfield);
        }


        var grades = ["k", 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        var genders = ['', 'female', 'male', 'other'];
        var races = ['', 'American Indian or Alaska Native', 'Asian', 'African American/Black', 'Multiracial', 'Native Hawaiian or Pacific Islander', 'Other', 'White'];
        var ethnicities = ['', 'Hispanic', 'Non-Hispanic'];

        var disabilities = ['', 'asd', 'deaf_blind', 'deaf', 'dd', 'ed', 'hi', 'ind', 'md', 'oi', 'ohi', 'sld', 'sli', 'tbi', 'vi'];
        var infoOptions = ['', 'Yes', 'No'];
        var relationships = [
            'Biological Mother', 'Biological Father', 'Non-Biological Mother', 'Non-Biological Father',
            'Guardian', 'Other Relative', 'Other (please specify)'
        ];



        var fileSplit = this.fileInput.name.split('.');
        var fileType = fileSplit[fileSplit.length - 1];
        if (fileType != "csv") statusTextfield += "Error: Wrong file format." + fileType + " instead of .csv.\n"

        if (statusTextfield != "") return this.setErrors(status, statusTextfield);

        titles.forEach(title => {
            if (results[0].indexOf(title) == -1) statusTextfield += "Error: No title " + title + ".\n";
        });
        if (statusTextfield != "") return this.setErrors(status, statusTextfield);

        var requiredTitlesIndex = [];
        requiredTitles.forEach(title => {
            requiredTitlesIndex.push(results[0].indexOf(title))
        });

        var counter = 0;
        var removedIndexes = [];
        var resLength = results.length;
        for (var i = 1; i < resLength; i++) {
            var row = results[i - counter];
            var emptyRow = true;
            for (var j = 1; j < results.length; j++) {
                var columnValue = row[j];
                if (columnValue != "" && columnValue != undefined) {
                    emptyRow = false;
                    break;
                }
            };
            if (emptyRow) {
                statusTextfield += "Update: Row " + (i + 1) + " - Deleted empty row.\n";
                removedIndexes.push(i - counter);
                results.splice(i - counter, 1);
                counter++;
            }
        };

        var birthdayIndex = results[0].indexOf("birth_date*");

        for (var i = 1; i < results.length; i++) {
            requiredTitlesIndex.forEach(ind => {
                var column = results[i][ind];
                var date = column.split('-')
                if (column == "") statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Empty field in title " + results[0][ind] + ".\n";
                else if (ind == birthdayIndex && (date.length != 3 || isNaN(Date.parse(`${date[2]}-${date[0]}-${date[1]}`)))) {
                    statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Not a valid date of birth.\n";
                }
            });
            results[i].forEach(function (column, j) {
                if (column != "" && column.trim().length == 0) statusTextfield += "Warning: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - This field consists only of empty spaces in title " + results[0][j] + ".\n";
            });
        }
        if (statusTextfield != "" && statusTextfield.search("Error:") != -1) return this.setErrors(status, statusTextfield);
        else {
            this.results = results;
            this.studentsToImport = this.prepareArray();

            var i = 1;
            this.studentsToImport.forEach((student) => {

                if (!isNaN(student.GradeName)) {
                    if (student.GradeName == 1 || student.GradeName == "k") {
                        student.GradeName = 'k';
                    } else {
                        student.GradeName = parseInt(student.GradeName) + 1;
                    }
                }

                if (!grades.includes(student.GradeName)) {
                    statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Not a valid input in title Grade.\n";
                }

                if (!genders.includes(student.Gender)) {
                    statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Not valid input in title Gender.\n";
                }
                if (!races.includes(student.Race)) {
                    statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Not valid input in title Race.\n";
                }
                if (!ethnicities.includes(student.Ethnicity)) {
                    statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Not valid input in title Ethnicity.\n";
                }

                if (!["", "1", "2", "3"].includes(student.MTSS)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at MTSS title.\n";
                if (!infoOptions.includes(student.IDEA)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at idea title.\n";
                if (!disabilities.includes(student.Disability)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at Disability title.\n";
                if (!["", "0", "1", "2", "3"].includes(student.MealStatus)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at Meal Status title.\n";

                if (!infoOptions.includes(student.ESL)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at ESL/ELL title.\n";
                if (!infoOptions.includes(student.Title1)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at Title 1 title.\n";
                if (!infoOptions.includes(student.Section504)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at Section 504 title.\n";
                if (!infoOptions.includes(student.Talented)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at Gifted/Talented title.\n";
                if (!infoOptions.includes(student.Mobility)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at Mobility title.\n";
                if (!infoOptions.includes(student.Correctional)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at Correctional title.\n";

                if (!["", "0", "1", "2", "3", "4", "5", "6"].includes(student.Intervention)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at Intervention Type title.\n";

                if (student.Guardian1Name && student.Guardian1Surname) {
                    if (!relationships.includes(student.Guardian1Relationship)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at Parent 1 Relationship title.\n";
                    if (!infoOptions.includes(student.Guardian1Custody)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at Parent 1 Custody title.\n";
                }

                if (student.Guardian2Name && student.Guardian2Surname) {
                    if (!relationships.includes(student.Guardian2Relationship)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at Parent 2 Relationship title.\n";
                    if (!infoOptions.includes(student.Guardian2Custody)) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at Parent 2 Custody title.\n";
                }

                i++;
            })

            if (statusTextfield != "" && statusTextfield.search("Error:") != -1) return this.setErrors(status, statusTextfield);
            else {
                this.studentsService.getStudentsActiveState().subscribe(
                    (students: StudentActiveDTO[]) => {
                        var activeCounter = 0;
                        var notActiveCounter = 0;
                        var newCounter = 0;
                        this.studentsToImport.forEach(student => {
                            var stud = students.find(s => s.Name == student.Name && s.Surname == student.Surname
                                && s.StudentId == student.StudentId && s.SchoolName == student.SchoolName);
                            if (stud) {
                                student.Id = stud.Id;
                                if (stud.Active) activeCounter++;
                                else notActiveCounter++;
                            }
                            else {
                                newCounter++;
                            }
                        });

                        status = true;
                        if (statusTextfield != "") statusTextfield += "\n";
                        statusTextfield += "Correct file format.\n\n"
                            + "New students:\t\t\t\t" + newCounter.toString() + "\n"
                            + "Activated students:\t\t\t" + activeCounter.toString() + "\n"
                            + "Deactivated students:\t\t" + notActiveCounter.toString() + "\n\n"
                            + "To the already existent students, activated or not, values will be updated according to data in the .CSV file. All students from the file will be set as 'Activated'.\n\n"
                            + "Press the button 'Import students' to continue.";

                        return this.setErrors(status, statusTextfield);
                    },
                    errors => this.authService.alertError("An error occurred while checking students' data. Please try again.")
                );
            }
        }
    }

    prepareArray() {
        const titleToAttribute = {
            'first_name*': 'Name',
            'last_name*': 'Surname',
            'student_id*': 'StudentId',
            'school*': 'SchoolName',
            'grade*': 'GradeName',
            'ua_teacher_email': 'RaterEmail',
            'birth_date*': 'DateofBirth',
            'gender': 'Gender',
            'esl': 'ESL',
            'race': 'Race',
            'hispanic': 'Ethnicity',
            'mtss*': 'MTSS',
            'idea': 'IDEA',
            'disability': 'Disability',
            'meal_status': 'MealStatus',
            'title_1': 'Title1',
            'section_504': 'Section504',
            'gifted_talented': 'Talented',
            'mobility': 'Mobility',
            'correctional': 'Correctional',
            'prior_retentions': 'PriorRetentions',
            'absences': 'Absences',
            'referrals': 'OfficeReferrals',
            'intervention_type': 'Intervention',
            'guardian_1_first_name': 'Guardian1Name',
            'guardian_1_last_name': 'Guardian1Surname',
            'guardian_1_email': 'Guardian1Email',
            'guardian_1_relationship': 'Guardian1Relationship',
            'guardian_2_first_name': 'Guardian2Name',
            'guardian_2_last_name': 'Guardian2Surname',
            'guardian_2_email': 'Guardian2Email',
            'guardian_2_relationship': 'Guardian2Relationship',
        };

        for (let i = 0; i < this.results.length; i++) {
            for (let j = 0; j < this.results[0].length; j++) {
                this.results[i][j] = this.results[i][j].trim();
            }
        }

        const studentAttributes = this.results[0].map(title => titleToAttribute[title] || title);
        const allStudents = [];

        for (let i = 1; i < this.results.length; i++) {
            const studentRow = this.results[i];
            const student = {};

            for (let j = 0; j < studentAttributes.length; j++) {
                const attribute = studentAttributes[j];

                if (attribute === 'DateofBirth') {
                    const dateParts = studentRow[j].split('-');
                    if (dateParts.length === 3) {
                        student[attribute] = [dateParts[2], dateParts[0], dateParts[1]].join('-');
                    } else {
                        student[attribute] = '';
                    }
                } else {
                    student[attribute] = studentRow[j] || '';
                }
            }

            allStudents.push(student);
        }

        return allStudents;
    }

    onCloseConfirm() {
        this.thisDialogRef.close(this.studentsToImport);
        setTimeout(() => {
            window.location.reload(); // TODO pass imported data to main page
        }, 2500);
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }
}