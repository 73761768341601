import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Pipes from './Pipes/index';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { MultipleSelectComponent } from './Components/multiple-select/multiple-select.component';
import { MatInputModule, MatButtonModule, MatCardModule, MatDialogModule, MatCheckboxModule, MatAutocompleteModule, MatFormFieldModule, MatSelectModule, MatTooltipModule, MatOptionModule, MatTabsModule, MatSnackBarModule } from '@angular/material';
import { EnsureActionModalComponent } from './Components/ensure-action-modal/ensure-action-modal.component';
import { AuthGuard } from './Guards/auth.guard';
import { RoleGuard } from './Guards/role.guard';
import { SuperadminGuard } from './Guards/superadmin.guard';
import { DetailsStaffComponent } from './Components/staff/details-staff/details-staff.component';
import { ActivateMemberStaffComponent } from './Components/staff/activate-member-staff/activate-member-staff.component';
import { ChangePasswordComponent } from './Components/staff/change-password/change-password.component';
import { DeactivateMemberStaffComponent } from './Components/staff/deactivate-member-staff/deactivate-member-staff.component';
import { LoginLinkStaffComponent } from './Components/staff/login-link-staff/login-link-staff.component';
import { ActivateStudentComponent } from './Components/students/activate-student/activate-student.component';
import { DeactivateStudentComponent } from './Components/students/deactivate-student/deactivate-student.component';
import { DeleteFileApprovalComponent } from './Components/students/delete-file-approval/delete-file-approval.component';
import { ShowAssessmentComponent } from './Components/students/show-assessment/show-assessment.component';
import { RouterModule } from '@angular/router';
import { DetailsStudentsComponent } from './Components/students/details-students/details-students.component';
import { PeriodsLineChartComponent } from './Components/students/periods-line-chart/periods-line-chart.component';
import { CompleteFormStudentComponent } from './Components/students/complete-form-student/complete-form-student.component';

import { AccumulationChartModule } from '@syncfusion/ej2-angular-charts';
import { PyramidSeriesService, CategoryService, AccumulationDataLabelService } from '@syncfusion/ej2-angular-charts';
import { AddEditAdminUaComponent } from './Components/ua-periods/add-edit-admin-ua/add-edit-admin-ua.component';

@NgModule({
  declarations: [
    Pipes.OrderByPipe,
    Pipes.SearchForPipe,
    Pipes.SplitByPipe,
    Pipes.SearchForSpecificPipe,
    Pipes.SearchColumnsPipe,
    Pipes.RatersSearchStudentPipe,
    Pipes.FilterStaffByGradePipe,

    MultipleSelectComponent,
    EnsureActionModalComponent,

    DetailsStudentsComponent,
    ShowAssessmentComponent,
    DeactivateStudentComponent,
    ActivateStudentComponent,
    DeleteFileApprovalComponent,

    DetailsStaffComponent,
    LoginLinkStaffComponent,
    DeactivateMemberStaffComponent,
    ActivateMemberStaffComponent,
    ChangePasswordComponent,
    CompleteFormStudentComponent,
    PeriodsLineChartComponent,

    AddEditAdminUaComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatOptionModule,
    MatTabsModule,
    MatSnackBarModule,

    AccumulationChartModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatOptionModule,
    MatTabsModule,
    MatSnackBarModule,

    NgxPaginationModule,
    AccumulationChartModule,

    Pipes.OrderByPipe,
    Pipes.SearchForPipe,
    Pipes.SplitByPipe,
    Pipes.SearchForSpecificPipe,
    Pipes.SearchColumnsPipe,
    Pipes.RatersSearchStudentPipe,
    Pipes.FilterStaffByGradePipe,

    MultipleSelectComponent,
    EnsureActionModalComponent,

    AddEditAdminUaComponent,
  ],
  providers: [
    AuthGuard,
    RoleGuard,
    SuperadminGuard,

    PyramidSeriesService,
    CategoryService,
    AccumulationDataLabelService,
  ],
  entryComponents: [
    DeactivateStudentComponent,
    ActivateStudentComponent,
    DeleteFileApprovalComponent,
    LoginLinkStaffComponent,
    DeactivateMemberStaffComponent,
    ActivateMemberStaffComponent,
    ChangePasswordComponent,
    EnsureActionModalComponent,
  ]
})
export class SharedModule { }
