import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import 'rxjs/Rx';
import { ActivatedRoute, Router } from '@angular/router';
import { UAPeriod } from '@app/CoreModule/Models/UAPeriod';
import { AuthService, UaPeriodService } from '@app/CoreModule/Services';


@Component({
    selector: 'app-add-edit-admin-ua',
    templateUrl: './add-edit-admin-ua.component.html',
    styleUrls: ['./add-edit-admin-ua.component.css']
})
export class AddEditAdminUaComponent implements OnInit {
    modeEdit = false;
    period: UAPeriod = {} as UAPeriod;
    allowEdit = false;

    constructor(private authService: AuthService, private uaPeriodService: UaPeriodService, public thisDialogRef: MatDialogRef<AddEditAdminUaComponent>, @Inject(MAT_DIALOG_DATA) public data: number, private route: ActivatedRoute, private router: Router) {
        this.period = new UAPeriod();

        var id = data; //+this.route.snapshot.paramMap.get('id');
        if (id) {
            this.modeEdit = true;
            this.uaPeriodService.getUAPeriodById(id).subscribe(
                result => {
                    this.period = result;
                    this.period.StartDate = this.fixDateFormat(this.period.StartDate);
                    this.period.EndDate = this.fixDateFormat(this.period.EndDate);
                    this.allowEdit = true;
                },
                error => {
                    if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                        authService.alertError(error.error)
                    }
                    else {
                        authService.alertError('An error occured while requesting UA period data from server. Please try again.')
                    }
                }
            )
        }
    }

    getNextDay(date: String) {
        var dateSplit = date.split("-").map(x => parseInt(x));
        var minDate = new Date(dateSplit[0], dateSplit[1], dateSplit[2]);
        minDate.setDate(minDate.getDate() + 1);
        return minDate.getFullYear() + "-" + (minDate.getMonth().toString().length < 2 ? "0" + minDate.getMonth() : minDate.getMonth()) + "-" + (minDate.getDate().toString().length < 2 ? "0" + minDate.getDate() : minDate.getDate());
    }

    fixDateFormat(date) {
        return date.toString().split("T")[0];
    }

    ngOnInit() {
    }

    onCloseConfirm() {
        this.thisDialogRef.close(this.period);
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }


}
